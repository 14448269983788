export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAXvihk8Ln2pxF_9q_vnxf9NiUgakN0CNY",
    authDomain: "talentforce-prod.firebaseapp.com",
    projectId: "talentforce-prod",
    storageBucket: "talentforce-prod.appspot.com",
    messagingSenderId: "241472400719",
    appId: "1:241472400719:web:2b6634d7b96d04be81693a",
    measurementId: "G-16PEH8HLX6",
  },
};
