import { Injectable, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { BehaviorSubject } from 'rxjs';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root',
})
export class FirebaseAuthService implements OnInit {
  readonly user$ = this.auth.authState;
  constructor(private auth: AngularFireAuth, private store: AngularFirestore) {}

  ngOnInit(): void {
    this.auth.user.subscribe((user) => console.log(user));
    this.auth.onIdTokenChanged((user) => console.log(user));
  }
  loginWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.auth.signInWithPopup(provider);
  }
  async LoginWithEmailAndPassword(email: string, password: string) {
    return await this.auth.signInWithEmailAndPassword(email, password);
  }
  LoginWithFacebook() {
    console.log('facebook auth not implemented');
  }

  updateUserData(user: any) {
    const userRef: AngularFirestoreDocument<any> = this.store.doc(
      `users/${user.uid}`
    );
    const data = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
    };
    userRef.set(data, { merge: true });
  }
}
