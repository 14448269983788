import { Injectable } from '@angular/core';
import { switchMap, catchError, take } from 'rxjs/operators';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { FirebaseAuthService } from 'src/app/services/firebase-auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserAuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private authService: FirebaseAuthService
  ) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.authService.user$.pipe(
      take(1),
      switchMap((user: any) => {
        return of(this.guardRequest(user, state));
      }),
      catchError(() => {
        this.router.navigate(['login'], {
          queryParams: { returnUrl: state.url },
        });
        return of(false);
      })
    );
  }

  guardRequest(user: any, state: RouterStateSnapshot) {
    console.log(user);
    if (user?.uid) {
      this.router.navigateByUrl('/home');
      return false;
    } else {
      this.router.navigate(['login'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.user$.pipe(
      take(1),
      switchMap((user: any) => {
        return of(this.guardRequest(user, state));
      }),
      catchError(() => {
        this.router.navigate(['login'], {
          queryParams: { returnUrl: state.url },
        });
        return of(false);
      })
    );
  }
}
