<!-- Navbar-->
<header
  class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top"
  data-scroll-header
  (window:scroll)="windowScroll()"
>
  <div class="container">
    <button class="navbar-toggler" type="button" (click)="toggleMobileMenu()">
      <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar-brand me-3 me-xl-4" routerLink=""
      ><img
        class="d-block"
        src="assets/img/logo/logo-light.svg"
        width="116"
        alt="Finder"
    /></a>

    <a
      class="btn btn-link btn-light btn-sm d-none d-lg-block order-lg-3"
      href="javascript:void(0);"
      (click)="toggleModal(toggleFirstModal)"
      data-bs-toggle="modal"
    >
      <i class="fi-user me-2"></i>Sign in
    </a>
    <a
      class="btn btn-primary btn-sm rounded-pill ms-2 order-lg-3"
      routerLink="/account/resume1"
      ><i class="fi-plus me-2"></i>Post resume</a
    >
    <a
      class="btn btn-link btn-light btn-sm d-none d-lg-block order-lg-3 pe-0 ms-2"
      routerLink="/employer"
      >For employers<i class="fi-arrow-long-right ms-2"></i
    ></a>
    <div class="collapse navbar-collapse order-lg-2" id="navbarNav">
      <ul class="navbar-nav navbar-nav-scroll" style="max-height: 35rem">
        <ng-container *ngFor="let item of menuItems">
          <li class="nav-item dropdown">
            <a
              class="nav-link"
              *ngIf="!item.subItems"
              (click)="onMenuClick($event); toggleMobileMenu()"
              id="topnav-components"
              routerLink="{{ item.link }}"
              role="button"
            >
              {{ item.label }}
            </a>

            <a
              *ngIf="item.subItems"
              class="nav-link dropdown-toggle"
              (click)="onMenuClick($event)"
              id="topnav-components"
              role="button"
            >
              {{ item.label }}
            </a>

            <ul
              class="dropdown-menu dropdown-menu-dark"
              aria-labelledby="topnav-dashboard"
              *ngIf="hasItems(item)"
            >
              <ng-template
                ngFor
                let-i="index"
                let-subitem
                [ngForOf]="item.subItems"
              >
                <a
                  class="col dropdown-item side-nav-link-ref"
                  *ngIf="!hasItems(subitem)"
                  routerLink="{{ subitem.link }}"
                  (click)="toggleMobileMenu()"
                  routerLinkActive="active"
                  >{{ subitem.label }}</a
                >

                <div class="dropdown" *ngIf="hasItems(subitem)">
                  <a
                    class="dropdown-item dropdown-toggle"
                    href="javascript:void(0);"
                    (click)="onMenuClick($event)"
                    >{{ subitem.label }}
                    <div class="arrow-down"></div>
                  </a>
                  <ul class="dropdown-menu dropdown-menu-dark">
                    <ng-template
                      ngFor
                      let-subSubitem
                      [ngForOf]="subitem.subItems"
                    >
                      <a
                        class="dropdown-item"
                        *ngIf="!hasItems(subSubitem)"
                        routerLink="{{ subSubitem.link }}"
                        (click)="toggleMobileMenu()"
                        routerLinkActive="active"
                        >{{ subSubitem.label }}</a
                      >
                    </ng-template>
                  </ul>
                </div>
              </ng-template>
            </ul>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</header>

<!-- Toggle Modal  -->
<!-- Sign In Modal-->
<ng-template #toggleFirstModal let-modal>
  <div class="modal-content">
    <div class="modal-body px-0 py-2 py-sm-0">
      <button
        class="btn-close position-absolute top-0 end-0 mt-3 me-3"
        type="button"
        data-bs-dismiss="modal"
        (click)="modal.dismiss('Cross click')"
      ></button>
      <div class="row mx-0 align-items-center">
        <div class="col-md-6 border-end-md p-4 p-sm-5">
          <h2 class="h3 mb-4 mb-sm-5">Hey there!<br />Welcome back.</h2>
          <img
            class="d-block mx-auto"
            src="assets/img/signin-modal/signin.svg"
            width="344"
            alt="Illustartion"
          />
          <div class="mt-4 mt-sm-5">
            Don't have an account?
            <a
              href="javascript:void(0);"
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
              (click)="secondModal(toggleSecondModal)"
              (click)="modal.close('Close click')"
              >Sign up here</a
            >
          </div>
        </div>
        <div class="col-md-6 px-4 pt-2 pb-4 px-sm-5 pb-sm-5 pt-md-5">
          <a
            class="btn btn-outline-info w-100 rounded-pill mb-3"
            href="javascript:void(0);"
            ><i class="fi-google fs-lg me-1"></i>Sign in with Google</a
          ><a
            class="btn btn-outline-info w-100 rounded-pill mb-3"
            href="javascript:void(0);"
            ><i class="fi-facebook fs-lg me-1"></i>Sign in with Facebook</a
          >
          <div class="d-flex align-items-center py-3 mb-3">
            <hr class="w-100" />
            <div class="px-3">Or</div>
            <hr class="w-100" />
          </div>
          <form
            class="needs-validation"
            novalidate
            (ngSubmit)="validSubmit()"
            [formGroup]="validationform"
          >
            <div class="mb-4">
              <label class="form-label mb-2" for="signin-email"
                >Email address</label
              >
              <input
                class="form-control"
                type="email"
                id="signin-email"
                placeholder="Enter your email"
                required
                formControlName="email"
                [ngClass]="{ 'is-invalid': submit && form['email'].errors }"
              />
            </div>
            <div class="mb-4">
              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <label class="form-label mb-0" for="signin-password"
                  >Password</label
                ><a class="fs-sm" href="javascript:void(0);"
                  >Forgot password?</a
                >
              </div>
              <div class="password-toggle">
                <input
                  class="form-control"
                  [type]="loginPassfield ? 'text' : 'password'"
                  id="signin-password"
                  id="signin-password"
                  placeholder="Enter password"
                  required
                  formControlName="password"
                  [ngClass]="{
                    'is-invalid': submit && form['password'].errors
                  }"
                />
                <label
                  class="password-toggle-btn"
                  aria-label="Show/hide password"
                >
                  <input class="password-toggle-check" type="checkbox" /><span
                    class="password-toggle-indicator"
                    [ngClass]="{
                      'mdi-eye-off-outline': !loginPassfield,
                      'mdi-eye-outline': loginPassfield
                    }"
                    (click)="toggleLoginPassField()"
                  ></span>
                </label>
              </div>
            </div>
            <button
              class="btn btn-primary btn-lg w-100 rounded-pill"
              type="submit"
            >
              Sign in
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Sign Up Modal-->
<ng-template #toggleSecondModal let-modal>
  <div class="modal-content">
    <div class="modal-body p-sm-5">
      <button
        class="btn-close position-absolute top-0 end-0 mt-3 me-3"
        type="button"
        data-bs-dismiss="modal"
        (click)="modal.dismiss('Cross click')"
      ></button>
      <ul
        ngbNav
        #nav="ngbNav"
        [activeId]="1"
        class="nav nav-pills flex-column flex-sm-row border-bottom pb-4 mt-sm-n2 my-4"
      >
        <li [ngbNavItem]="1" class="nav-item me-sm-3 mb-sm-0">
          <a ngbNavLink> I am job seeker </a>
          <ng-template ngbNavContent>
            <div
              class="tab-pane fade show active"
              id="job-seeker"
              role="tabpanel"
            >
              <h3>Register to add a resume</h3>
              <p class="pb-3">
                Get access to all the functions of the site that will help you
                find a great job.
              </p>
              <div class="row gx-2 gx-md-4">
                <div class="col-sm-6 mb-3">
                  <a
                    class="btn btn-outline-info w-100 rounded-pill"
                    href="javascript:void(0);"
                    ><i class="fi-google fs-lg me-1"></i>Sign in with Google</a
                  >
                </div>
                <div class="col-sm-6 mb-3">
                  <a
                    class="btn btn-outline-info w-100 rounded-pill"
                    href="javascript:void(0);"
                    ><i class="fi-facebook fs-lg me-1"></i>Sign in with
                    Facebook</a
                  >
                </div>
              </div>
              <div class="d-flex align-items-center py-2 mb-2">
                <hr class="w-100" />
                <div class="px-3">Or</div>
                <hr class="w-100" />
              </div>
              <form
                class="needs-validation"
                novalidate
                (ngSubmit)="formSubmit()"
                [formGroup]="signUpform"
              >
                <div class="row gx-2 gx-md-4">
                  <div class="col-sm-6 mb-4">
                    <label class="form-label" for="js-fn">Full name</label>
                    <input
                      class="form-control"
                      type="text"
                      id="js-fn"
                      placeholder="Enter your full name"
                      required
                      [ngClass]="{
                        'is-invalid': formsubmit && formData['name'].errors
                      }"
                    />
                  </div>
                  <div class="col-sm-6 mb-4">
                    <label class="form-label" for="js-email"
                      >Emaill address</label
                    >
                    <input
                      class="form-control"
                      type="email"
                      id="js-email"
                      placeholder="Enter your email"
                      required
                      [ngClass]="{
                        'is-invalid': formsubmit && formData['email'].errors
                      }"
                    />
                  </div>
                  <div class="col-sm-6 mb-4">
                    <label class="form-label" for="js-password"
                      >Password
                      <span class="text-muted">(min. 8 char)</span></label
                    >
                    <div class="password-toggle">
                      <input
                        class="form-control"
                        [type]="signupPassfield ? 'text' : 'password'"
                        id="js-password"
                        minlength="8"
                        required
                        [ngClass]="{
                          'is-invalid':
                            formsubmit && formData['password'].errors
                        }"
                      />
                      <label
                        class="password-toggle-btn"
                        aria-label="Show/hide password"
                      >
                        <input
                          class="password-toggle-check"
                          type="checkbox"
                        /><span
                          class="password-toggle-indicator"
                          [ngClass]="{
                            'mdi-eye-off-outline': !signupPassfield,
                            'mdi-eye-outline': signupPassfield
                          }"
                          (click)="toggleSignUpPassField()"
                        ></span>
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-6 mb-4">
                    <label class="form-label" for="js-password-confirm"
                      >Confirm password</label
                    >
                    <div class="password-toggle">
                      <input
                        class="form-control"
                        [type]="signupCPassfield ? 'text' : 'password'"
                        id="js-password-confirm"
                        minlength="8"
                        required
                        [ngClass]="{
                          'is-invalid':
                            formsubmit && formData['password'].errors
                        }"
                      />
                      <label
                        class="password-toggle-btn"
                        aria-label="Show/hide password"
                      >
                        <input
                          class="password-toggle-check"
                          type="checkbox"
                        /><span
                          class="password-toggle-indicator"
                          [ngClass]="{
                            'mdi-eye-off-outline': !signupCPassfield,
                            'mdi-eye-outline': signupCPassfield
                          }"
                          (click)="toggleSignUpCPassField()"
                        ></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="form-check mb-4">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="agree-to-terms"
                    required
                  />
                  <label class="form-check-label" for="agree-to-terms"
                    >By joining, I agree to the
                    <a href="javascript:void(0);">Terms of use</a> and
                    <a href="javascript:void(0);">Privacy policy</a></label
                  >
                </div>
                <button
                  class="btn btn-primary btn-lg w-100 rounded-pill"
                  type="submit"
                >
                  Sign up
                </button>
              </form>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="2" class="nav-item mb-sm-0">
          <a ngbNavLink> I am employer </a>
          <ng-template ngbNavContent>
            <div
              class="tab-pane fade show active"
              id="employer"
              role="tabpanel"
            >
              <h3>Register to post a vacancy</h3>
              <p class="pb-3">
                Get access to all special services for employers on Finder.
              </p>
              <form
                class="needs-validation"
                novalidate
                (ngSubmit)="employersSubmit()"
                [formGroup]="employersform"
              >
                <div class="row gx-2 gx-md-4">
                  <div class="col-sm-6 mb-4">
                    <label class="form-label" for="em-fn">Full name</label>
                    <input
                      class="form-control"
                      type="text"
                      id="em-fn"
                      placeholder="Enter your full name"
                      required
                      [ngClass]="{
                        'is-invalid': formsubmit && formsData['name'].errors
                      }"
                    />
                    <div class="invalid-feedback">
                      Please enter you full name.
                    </div>
                  </div>
                  <div class="col-sm-6 mb-4">
                    <label class="form-label" for="em-email"
                      >Emaill
                      <span class="text-muted">(better corporate)</span></label
                    >
                    <input
                      class="form-control"
                      type="email"
                      id="em-email"
                      placeholder="Enter email"
                      required
                      [ngClass]="{
                        'is-invalid': formsubmit && formsData['email'].errors
                      }"
                    />
                    <div class="invalid-feedback">Please enter you email.</div>
                  </div>
                  <div class="col-sm-6 mb-4">
                    <label class="form-label" for="em-company"
                      >Company name</label
                    >
                    <input
                      class="form-control"
                      type="text"
                      id="em-company"
                      placeholder="Enter company name"
                      required
                      [ngClass]="{
                        'is-invalid': formsubmit && formsData['cname'].errors
                      }"
                    />
                    <div class="invalid-feedback">
                      Please enter you company name.
                    </div>
                  </div>
                  <div class="col-sm-6 mb-4">
                    <label class="form-label" for="em-location"
                      >Main office location</label
                    >
                    <input
                      class="form-control"
                      type="text"
                      id="em-location"
                      placeholder="Country, City, Address"
                      required
                      [ngClass]="{
                        'is-invalid': formsubmit && formsData['location'].errors
                      }"
                    />
                    <div class="invalid-feedback">
                      Please enter you location.
                    </div>
                  </div>
                  <div class="col-sm-6 mb-4">
                    <label class="form-label" for="em-password"
                      >Password
                      <span class="text-muted">(min. 8 char)</span></label
                    >
                    <div class="password-toggle">
                      <input
                        class="form-control"
                        [type]="signupPassfield ? 'text' : 'password'"
                        id="em-password"
                        minlength="8"
                        required
                        [ngClass]="{
                          'is-invalid':
                            formsubmit && formsData['password'].errors
                        }"
                      />
                      <div class="invalid-feedback">
                        Please enter you password.
                      </div>
                      <label
                        class="password-toggle-btn"
                        aria-label="Show/hide password"
                      >
                        <input
                          class="password-toggle-check"
                          type="checkbox"
                        /><span
                          class="password-toggle-indicator"
                          [ngClass]="{
                            'mdi-eye-off-outline': !signupPassfield,
                            'mdi-eye-outline': signupPassfield
                          }"
                          (click)="toggleSignUpPassField()"
                        ></span>
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-6 mb-4">
                    <label class="form-label" for="em-password-confirm"
                      >Confirm password</label
                    >
                    <div class="password-toggle">
                      <input
                        class="form-control"
                        [type]="signupCPassfield ? 'text' : 'password'"
                        id="em-password-confirm"
                        minlength="8"
                        required
                      />
                      <label
                        class="password-toggle-btn"
                        aria-label="Show/hide password"
                      >
                        <input
                          class="password-toggle-check"
                          type="checkbox"
                        /><span
                          class="password-toggle-indicator"
                          [ngClass]="{
                            'mdi-eye-off-outline': !signupCPassfield,
                            'mdi-eye-outline': signupCPassfield
                          }"
                          (click)="toggleSignUpCPassField()"
                        ></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="form-check mb-4">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="agree-to-terms2"
                    required
                  />
                  <label class="form-check-label" for="agree-to-terms2"
                    >By joining, I agree to the
                    <a href="javascript:void(0);">Terms of use</a> and
                    <a href="javascript:void(0);">Privacy policy</a></label
                  >
                </div>
                <button
                  class="btn btn-primary btn-lg w-100 rounded-pill"
                  type="submit"
                >
                  Sign up
                </button>
              </form>
            </div>
          </ng-template>
        </li>
      </ul>
      <div class="tab-content">
        <div [ngbNavOutlet]="nav"></div>
      </div>
      <div class="pt-4 pb-3 pb-sm-0 mt-2">
        Already have an account?
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-dismiss="modal"
          (click)="toggleModal(toggleFirstModal)"
          (click)="modal.close('Close click')"
          >Sign in</a
        >
      </div>
    </div>
  </div>
</ng-template>
