import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Page Routing
import { PagesRoutingModule } from './pages-routing.module';
import { SharedModule } from '../shared/shared.module';
import { LottieModule } from 'ngx-lottie';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import player from 'lottie-web';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [PageNotFoundComponent],
  imports: [
    CommonModule,
    PagesRoutingModule,
    SharedModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
})
export class PagesModule {}
