<div class="min-vh-100 d-flex align-items-center my-5 justify-content-center">
  <div class="container-fluid">
    <div class="card card-body mx-auto" style="max-width: 940px">
      <a
        class="position-absolute top-0 end-0 nav-link fs-sm py-1 px-2 mt-3 me-3"
        routerLink=""
        ><i class="fi-arrow-long-left fs-base me-2"></i>Go back</a
      >
      <div class="row mx-0 align-items-center">
        <div class="col-md-6 border-end-md p-2 p-sm-5">
          <h2 class="h3 mb-4 mb-sm-5">Hey there!<br />Welcome back.</h2>
          <img
            class="d-block mx-auto"
            src="assets/img/signin-modal/signin.svg"
            width="344"
            alt="Illustartion"
          />
          <div class="mt-4 mt-sm-5">
            Don't have an account? <a routerLink="/auth/signup">Sign up here</a>
          </div>
        </div>

        <div class="col-md-6 px-2 pt-2 pb-4 px-sm-5 pb-sm-5 pt-md-5">
          <a
            class="btn btn-outline-info w-100 mb-3"
            (click)="signInWithGoogle()"
            ><i class="fi-google fs-lg me-1"></i>Sign in with Google</a
          >

          <!--<a class="btn btn-outline-info w-100 mb-3" href="javascript:void(0);"
            ><i class="fi-facebook fs-lg me-1"></i>Sign in with Facebook</a
          >-->
          <div class="d-flex align-items-center py-3 mb-3">
            <hr class="w-100" />
            <div class="px-3">Or</div>
            <hr class="w-100" />
          </div>
          <form
            class="needs-validation"
            novalidate
            (ngSubmit)="validSubmit()"
            [formGroup]="validationform"
          >
            <div class="mb-4">
              <div *ngIf="message?.error">
                <div class="alert alert-danger" role="alert">
                  {{ message?.error }}
                </div>
              </div>

              <label class="form-label mb-2" for="signin-email"
                >Email address</label
              >
              <input
                class="form-control"
                type="email"
                id="signin-email"
                autocomplete
                placeholder="Enter your email"
                required
                formControlName="email"
                [ngClass]="{ 'is-invalid': submit && form['email'].errors }"
              />
              <div class="invalid-feedback">Please enter you email.</div>
            </div>
            <div class="mb-4">
              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <label class="form-label mb-0" for="signin-password"
                  >Password</label
                ><a class="fs-sm" href="javascript:void(0);"
                  >Forgot password?</a
                >
              </div>
              <div class="password-toggle">
                <input
                  class="form-control"
                  autocomplete
                  [type]="fieldTextType ? 'text' : 'password'"
                  id="signin-password"
                  placeholder="Enter password"
                  required
                  formControlName="password"
                  [ngClass]="{
                    'is-invalid': submit && form['password'].errors
                  }"
                />
                <div class="invalid-feedback">Please enter you password.</div>
                <label
                  class="password-toggle-btn"
                  aria-label="Show/hide password"
                >
                  <input class="password-toggle-check" type="checkbox" /><span
                    class="password-toggle-indicator"
                    [ngClass]="{
                      'mdi-eye-off-outline': !fieldTextType,
                      'mdi-eye-outline': fieldTextType
                    }"
                    (click)="toggleFieldTextType()"
                  ></span>
                </label>
              </div>
            </div>
            <button class="btn btn-primary btn-lg w-100" type="submit">
              Sign in
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
